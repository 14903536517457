<template>
  <b-card-code
    no-body
    title="Auction List"
  >
    <div class="dt_adv_search ml-1 mb-1 mr-1">
      <div class="row">

        <div class="col-md-12">
          <b-button
            v-if="checkPermission('create auctions')"
            v-b-modal.form-auction-modal
            class="btn btn-info btn-sm mb-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Add Auction"
            @click="cleanupForm()"
          >
            <plus-circle-icon
              size="1x"
              class="custom-class"
            /> Add Auction
          </b-button>

          <b-modal
            id="form-auction-modal"
            no-close-on-backdrop
            size="lg"
            :title="editUuid != null ? 'Edit Auction' : 'Add Auction'"
          >
            <div class="form">

              <div class="border border-light rounded p-1 mb-1">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Repeat Option:</label>
                      <select
                        v-model="formPayload.repeat_type"
                        class="form-control"
                      >
                        <option value="">
                          One Time Auction
                        </option>
                        <option value="daily">
                          Daily
                        </option>
                        <option value="weekly">
                          Weekly
                        </option>
                        <option value="monthly">
                          Monthly
                        </option>
                      </select>
                    </div>
                  </div>

                  <div
                    v-if="formPayload.repeat_type == 'weekly' || formPayload.repeat_type == 'monthly'"
                    class="col-6"
                  >
                    <label
                      for=""
                      class="text-capitalize"
                    >{{ formPayload.repeat_type }} on:</label>
                    <div class="form-group">

                      <select
                        v-if="formPayload.repeat_type == 'weekly'"
                        v-model="formPayload.repeat_at"
                        class="form-control"
                      >
                        <option
                          selected
                          value=""
                        >
                          -- Select Day --
                        </option>
                        <option
                          v-for="day, index in ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
                          :key="index"
                          :value="index + 1"
                        >
                          {{ day }}
                        </option>
                      </select>

                      <select
                        v-if="formPayload.repeat_type == 'monthly'"
                        v-model="formPayload.repeat_at"
                        class="form-control"
                      >
                        <option
                          selected
                          value=""
                        >
                          -- Select Date --
                        </option>
                        <option
                          v-for="date in range(1, 31)"
                          :key="date"
                          :value="date + 1"
                        >
                          {{ date + 1 }}
                        </option>
                      </select>

                    </div>
                  </div>
                </div>

                <div
                  v-if="formPayload.repeat_type != '' && formPayload.repeat_type != null"
                  class="mb-1"
                >
                  <div class="custom-control custom-checkbox">
                    <input
                      id="skip_weekend"
                      v-model="formPayload.repeat_skip_weekend_holiday"
                      class="custom-control-input"
                      type="checkbox"
                      name="skip_weekend"
                    >
                    <label
                      class="custom-control-label"
                      for="skip_weekend"
                    >
                      Skip Weekend &amp; Holiday
                    </label>
                  </div>
                  <br>

                  <div
                    v-if="(formPayload.repeat_type != '' && formPayload.repeat_type != null) && editUuid != null"
                    class="mb-1"
                  >
                    <label for="">Repeat in Depositories</label>
                    <div
                      v-for="warehouse in warehouses"
                      :key="warehouse.slug"
                    >
                      <div class="custom-control custom-checkbox">
                        <input
                          :id="'warehouse' + warehouse.slug"
                          v-model="formPayload.repeat_warehouses"
                          class="custom-control-input"
                          name="schedule.warehouses"
                          type="checkbox"
                          :value="warehouse.slug"
                        >
                        <label
                          class="custom-control-label"
                          :for="'warehouse' + warehouse.slug"
                        >
                          {{ warehouse.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div
                v-if="editUuid != null"
                class="form-group"
              >
                <label for="">Depository</label>
                <select
                  v-model="formPayload.warehouse_slug"
                  class="form-control"
                >
                  <option
                    selected
                    value=""
                  >
                    -- Select Depository --
                  </option>
                  <option
                    v-for="warehouse in warehouses"
                    :key="warehouse.slug"
                    :value="warehouse.slug"
                  >
                    {{ warehouse.name }}
                  </option>
                </select>
              </div>

              <div
                v-if="editUuid == null"
                class="form-group"
              >
                <label for="">Depository</label>
                <div
                  v-for="warehouse in warehouses"
                  :key="warehouse.slug"
                >
                  <div class="custom-control custom-checkbox">
                    <input
                      :id="'warehouse' + warehouse.slug"
                      v-model="formPayload.repeat_warehouses"
                      class="custom-control-input"
                      name="schedule.warehouses"
                      type="checkbox"
                      :value="warehouse.slug"
                    >
                    <label
                      class="custom-control-label"
                      :for="'warehouse' + warehouse.slug"
                    >
                      {{ warehouse.name }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="">Asset</label>
                <v-select
                  v-model="formPayload.commodity_slug"
                  :options="commodities"
                  :reduce="commodity => commodity.slug"
                  label="name"
                  placeholder="-- Select commodity --"
                  @input="getCommodityBySlug"
                />              </div>

              <div
                v-if="formPayload.trading_algorithm != 'seller_auction'"
                class="form-group"
              >
                <label for="">Open Price/Starting Price (Optional)</label>
                <input
                  v-model="formPayload.sob"
                  type="text"
                  min="1"
                  class="form-control"
                >
                <small class="text-info">Keep it empty if you want to automatic get from last auction price.</small>
                <br><strong v-if="formPayload.current_sob">Current Open Price: {{ formPayload.current_sob }}</strong>
              </div>

              <br>
              <h5>Time Rule</h5>

              <div class="form-group">
                <label for="">Date</label><br>
                <flat-pickr
                  v-model="formPayload.date"
                  class="form-control"
                  placeholder="Select date"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d', static: true}"
                />
              </div>

              <div class="row time-wrapper">

                <div
                  v-for="granted, index in formPayload.times"
                  :key="index"
                  class="col-md-12 mb-1"
                >
                  <div class="border border-light rounded p-1">
                    <div class="row">

                      <div class="col-5 mb-1">
                        <label>Auction Start Time</label><br>
                        <the-mask
                          v-model="formPayload.times[index].start_at"
                          :mask="['##:##:##']"
                          placeholder="hh:mm:ss"
                          masked="true"
                          class="form-control"
                        />
                      </div>
                      <div class="col-5 mb-1">
                        <label>Auction End Time</label><br>
                        <the-mask
                          v-model="formPayload.times[index].end_at"
                          :mask="['##:##:##']"
                          placeholder="hh:mm:ss"
                          masked="true"
                          class="form-control"
                        />
                      </div>
                      <div class="col-2">
                        <button
                          v-if="index != 0"
                          class="btn btn-sm btn-danger ml-1 mt-2"
                          @click="removeTime(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </button>
                      </div>
                      <div class="col-5">
                        <label>Break Time (Optional)</label><br>
                        <the-mask
                          v-model="formPayload.times[index].break_at"
                          :mask="['##:##:##']"
                          placeholder="hh:mm:ss"
                          masked="true"
                          class="form-control"
                        />
                      </div>

                      <div class="col-5">
                        <label>Break Time End (Optional)</label><br>
                        <the-mask
                          v-model="formPayload.times[index].break_finish_at"
                          :mask="['##:##:##']"
                          placeholder="hh:mm:ss"
                          masked="true"
                          class="form-control"
                        />
                      </div>

                    </div>
                  </div>
                </div>

              </div>

              <button
                class="btn btn-sm btn-success mb-1"
                @click="addTime()"
              >
                <feather-icon
                  icon="PlusIcon"
                /> Add Session
              </button>

            </div>

            <br>
            <h5>Transaction Rule</h5>

            <div class="form-group">
              <label for="">Trading Algorithm:</label>
              <select
                v-model="formPayload.trading_algorithm"
                class="form-control"
              >
                <option value="running_trade">
                  Running Trade
                </option>
                <option value="dutch_auction">
                  Dutch Auction
                </option>
                <option value="seller_auction">
                  Seller Auction
                </option>
              </select>
            </div>

            <div v-if="formPayload.trading_algorithm == 'seller_auction'">
              <div class="form-group">
                <label for="">Seller:</label>
                <v-select
                  v-model="formPayload.seller_id"
                  :options="maskedUsers"
                  :reduce="(option) => option.userId"
                  label="userLabel"
                />
              </div>

              <div class="form-group">
                <label for="">Lot to Trade</label>
                <div class="input-group">
                  <input
                    v-model="formPayload.lot_to_trade"
                    type="text"
                    class="form-control"
                    placeholder="Lot to Trade"
                  >
                  <div class="input-group-append">
                    <span class="input-group-text">lot</span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="">Bid Starting Price</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{ currency.value }}</span>
                  </div>
                  <input
                    v-model="formPayload.bid_starting_price"
                    type="text"
                    min="1"
                    class="form-control"
                  >
                </div>
              </div>

              <div class="form-group">
                <label for="">Bid Minimum Price</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{ currency.value }}</span>
                  </div>
                  <input
                    v-model="formPayload.bid_minimum_price"
                    type="text"
                    min="1"
                    class="form-control"
                  >
                </div>
              </div>

              <div class="form-group">
                <label for="">Buy Now Price</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{ currency.value }}</span>
                  </div>
                  <input
                    v-model="formPayload.buy_now_price"
                    type="text"
                    min="1"
                    class="form-control"
                  >
                </div>
              </div>

            </div>

            <div class="form-group">
              <label for="restricted_to_trade">Restricted to Trade:</label>
              <div class="custom-control custom-checkbox">
                <input
                  id="restricted_to_trade"
                  v-model="formPayload.restricted_to_trade"
                  class="custom-control-input"
                  type="checkbox"
                  name="restricted_to_trade"
                >
                <label
                  class="custom-control-label"
                  :for="'restricted_to_trade'"
                >
                  Yes
                </label>
              </div>
              <small class="text-info">If you check this, auction will disabled to users untill admin granted user to buy/sell commodity</small>
            </div>

            <div v-if="formPayload.trading_algorithm != 'seller_auction'">
              <div class="form-group">
                <label for="">Price Movement:</label>
                <input
                  v-model="formPayload.price_movement"
                  type="text"
                  class="form-control"
                  placeholder="Price movement"
                >
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Limit Increase Type:</label>
                    <select
                      v-model="formPayload.limit_price_increase_type"
                      class="form-control"
                    >
                      <option value="percent">
                        Percent
                      </option>
                      <option value="fixed">
                        Fixed
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Limit Decrease Type:</label>
                    <select
                      v-model="formPayload.limit_price_decrease_type"
                      class="form-control"
                    >
                      <option value="percent">
                        Percent
                      </option>
                      <option value="fixed">
                        Fixed
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Limit Price <span v-if="formPayload.limit_price_increase_type == 'percent'">Increase</span><span v-else>High</span>:</label>
                    <div class="input-group">
                      <div
                        v-if="['fixed'].includes(formPayload.limit_price_increase_type)"
                        class="input-group-prepend"
                      >
                        <span class="input-group-text">{{ currency.value }}</span>
                      </div>
                      <input
                        v-model="formPayload.limit_price_increase"
                        type="text"
                        class="form-control"
                        placeholder="Limit Price Increase"
                      >
                      <div
                        v-if="['percent'].includes(formPayload.limit_price_increase_type)"
                        class="input-group-append"
                      >
                        <span class="input-group-text">%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Limit Price <span v-if="formPayload.limit_price_decrease_type == 'percent'">Decrease</span><span v-else>Low</span>:</label>
                    <div class="input-group">
                      <div
                        v-if="['fixed'].includes(formPayload.limit_price_decrease_type)"
                        class="input-group-prepend"
                      >
                        <span class="input-group-text">{{ currency.value }}</span>
                      </div>
                      <input
                        v-model="formPayload.limit_price_decrese"
                        type="text"
                        class="form-control"
                        placeholder="Limit Price Decrease"
                      >
                      <div
                        v-if="['percent'].includes(formPayload.limit_price_decrease_type)"
                        class="input-group-append"
                      >
                        <span class="input-group-text">%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="">Minimum Lot Transaction:</label>
              <input
                v-model="formPayload.minimum_lot_transaction"
                type="text"
                class="form-control"
                placeholder="Minimum Lot Transaction"
              >
            </div>

            <div class="form-group">
              <label for="">Lot Tolerance:</label>
              <div class="input-group">
                <input
                  v-model="formPayload.lot_tolerance"
                  type="text"
                  class="form-control"
                  placeholder="Lot Tolerance"
                >
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>

            <div v-if="!isTerra">
              <br>
              <h5>Margin for Buy</h5>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Margin Buy Type:</label>
                    <select
                      v-model="formPayload.margin_minimum_for_buy_type"
                      class="form-control"
                    >
                      <option
                        v-for="type in priceType"
                        :key="type.key"
                        :value="type.key"
                      >
                        {{ type.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <label for="">Margin Buy Amount:</label>
                  <div class="input-group">
                    <div
                      v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(formPayload.margin_minimum_for_buy_type)"
                      class="input-group-prepend"
                    >
                      <span class="input-group-text">{{ currency.value }}</span>
                    </div>
                    <input
                      v-model="formPayload.margin_minimum_for_buy"
                      type="text"
                      class="form-control"
                    >
                    <div
                      v-if="formPayload.margin_minimum_for_buy_type == 'percent'"
                      class="input-group-append"
                    >
                      <span class="input-group-text">%</span>
                    </div>
                    <div
                      v-if="['price_per_unit', 'price_per_lot'].includes(formPayload.margin_minimum_for_buy_type)"
                      class="input-group-append"
                    >
                      <span
                        v-if="formPayload.margin_minimum_for_buy_type == 'price_per_unit'"
                        class="input-group-text"
                      >per unit</span>
                      <span
                        v-if="formPayload.margin_minimum_for_buy_type == 'price_per_lot'"
                        class="input-group-text"
                      >per lot</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="(formPayload.margin_minimum_for_buy_type == 'percent' && formPayload.margin_minimum_for_buy >= 100)"
                  class="col-md-12"
                >
                  <div class="alert alert-info p-1">
                    Margin for buy 100% mean transaction will be settlement on match complete.
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Buyer Penalty Type:</label>
                    <select
                      v-model="formPayload.buyer_penalty_type"
                      class="form-control"
                    >
                      <option
                        v-for="type in priceType"
                        :key="type.key"
                        :value="type.key"
                      >
                        {{ type.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <label for="">Buyer Penalty Amount:</label>
                  <div class="input-group">
                    <div
                      v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(formPayload.buyer_penalty_type)"
                      class="input-group-prepend"
                    >
                      <span class="input-group-text">{{ currency.value }}</span>
                    </div>
                    <input
                      v-model="formPayload.buyer_penalty_amount"
                      type="text"
                      class="form-control"
                    >
                    <div
                      v-if="formPayload.buyer_penalty_type == 'percent'"
                      class="input-group-append"
                    >
                      <span class="input-group-text">%</span>
                    </div>
                    <div
                      v-if="['price_per_unit', 'price_per_lot'].includes(formPayload.buyer_penalty_type)"
                      class="input-group-append"
                    >
                      <span
                        v-if="formPayload.buyer_penalty_type == 'price_per_unit'"
                        class="input-group-text"
                      >per unit</span>
                      <span
                        v-if="formPayload.buyer_penalty_type == 'price_per_lot'"
                        class="input-group-text"
                      >per lot</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Seller Compensation Type:</label>
                    <select
                      v-model="formPayload.seller_compensation_type"
                      class="form-control"
                    >
                      <option
                        v-for="type in priceType"
                        :key="type.key"
                        :value="type.key"
                      >
                        {{ type.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <label for="">Seller Compensation Amount:</label>
                  <div class="input-group">
                    <div
                      v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(formPayload.seller_compensation_type)"
                      class="input-group-prepend"
                    >
                      <span class="input-group-text">{{ currency.value }}</span>
                    </div>
                    <input
                      v-model="formPayload.seller_compensation_amount"
                      type="text"
                      class="form-control"
                    >
                    <div
                      v-if="formPayload.seller_compensation_type == 'percent'"
                      class="input-group-append"
                    >
                      <span class="input-group-text">%</span>
                    </div>
                    <div
                      v-if="['price_per_unit', 'price_per_lot'].includes(formPayload.seller_compensation_type)"
                      class="input-group-append"
                    >
                      <span
                        v-if="formPayload.seller_compensation_type == 'price_per_unit'"
                        class="input-group-text"
                      >per unit</span>
                      <span
                        v-if="formPayload.seller_compensation_type == 'price_per_lot'"
                        class="input-group-text"
                      >per lot</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isTerra">
              <br>
              <h5>Margin for Sell</h5>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Margin Sell Type:</label>
                    <select
                      v-model="formPayload.margin_for_sell_type"
                      class="form-control"
                    >
                      <option
                        v-for="type in priceType"
                        :key="type.key"
                        :value="type.key"
                      >
                        {{ type.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <label for="">Margin Sell Amount:</label>
                  <div class="input-group">
                    <div
                      v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(formPayload.margin_for_sell_type)"
                      class="input-group-prepend"
                    >
                      <span class="input-group-text">{{ currency.value }}</span>
                    </div>
                    <input
                      v-model="formPayload.margin_for_sell_amount"
                      type="text"
                      class="form-control"
                    >
                    <div
                      v-if="formPayload.margin_for_sell_type == 'percent'"
                      class="input-group-append"
                    >
                      <span class="input-group-text">%</span>
                    </div>
                    <div
                      v-if="['price_per_unit', 'price_per_lot'].includes(formPayload.margin_for_sell_type)"
                      class="input-group-append"
                    >
                      <span
                        v-if="formPayload.margin_for_sell_type == 'price_per_unit'"
                        class="input-group-text"
                      >per unit</span>
                      <span
                        v-if="formPayload.margin_for_sell_type == 'price_per_lot'"
                        class="input-group-text"
                      >per lot</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Seller Penalty Type:</label>
                    <select
                      v-model="formPayload.seller_penalty_type"
                      class="form-control"
                    >
                      <option
                        v-for="type in priceType"
                        :key="type.key"
                        :value="type.key"
                      >
                        {{ type.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <label for="">Seller Penalty Amount:</label>
                  <div class="input-group">
                    <div
                      v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(formPayload.seller_penalty_type)"
                      class="input-group-prepend"
                    >
                      <span class="input-group-text">{{ currency.value }}</span>
                    </div>
                    <input
                      v-model="formPayload.seller_penalty_amount"
                      type="text"
                      class="form-control"
                    >
                    <div
                      v-if="formPayload.seller_penalty_type == 'percent'"
                      class="input-group-append"
                    >
                      <span class="input-group-text">%</span>
                    </div>
                    <div
                      v-if="['price_per_unit', 'price_per_lot'].includes(formPayload.seller_penalty_type)"
                      class="input-group-append"
                    >
                      <span
                        v-if="formPayload.seller_penalty_type == 'price_per_unit'"
                        class="input-group-text"
                      >per unit</span>
                      <span
                        v-if="formPayload.seller_penalty_type == 'price_per_lot'"
                        class="input-group-text"
                      >per lot</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Buyer Compensation Type:</label>
                    <select
                      v-model="formPayload.buyer_compensation_type"
                      class="form-control"
                    >
                      <option
                        v-for="type in priceType"
                        :key="type.key"
                        :value="type.key"
                      >
                        {{ type.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <label for="">Buyer Compensation Amount:</label>
                  <div class="input-group">
                    <div
                      v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(formPayload.buyer_compensation_type)"
                      class="input-group-prepend"
                    >
                      <span class="input-group-text">{{ currency.value }}</span>
                    </div>
                    <input
                      v-model="formPayload.buyer_compensation_amount"
                      type="text"
                      class="form-control"
                    >
                    <div
                      v-if="formPayload.buyer_compensation_type == 'percent'"
                      class="input-group-append"
                    >
                      <span class="input-group-text">%</span>
                    </div>
                    <div
                      v-if="['price_per_unit', 'price_per_lot'].includes(formPayload.buyer_compensation_type)"
                      class="input-group-append"
                    >
                      <span
                        v-if="formPayload.buyer_compensation_type == 'price_per_unit'"
                        class="input-group-text"
                      >per unit</span>
                      <span
                        v-if="formPayload.buyer_compensation_type == 'price_per_lot'"
                        class="input-group-text"
                      >per lot</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isTerra">
              <br>
              <h5>Settlement Rule</h5>

              <div class="row">
                <div class="col-md-5">

                  <div class="form-group">
                    <label for="settlement_days">Days Settlement:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">T+</span>
                      </div>
                      <input
                        id="settlement_days"
                        v-model="formPayload.settlement_days"
                        type="text"
                        class="form-control"
                        min="0"
                      >
                      <div class="input-group-append">
                        <span class="input-group-text">days</span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-7">

                  <div
                    v-if="formPayload.settlement_days > 0"
                    class="form-group"
                  >
                    <label for="is_settlement_only_weekday">Settlement Working Day/Calendar Day:</label>
                    <div
                      class="custom-control custom-checkbox"
                      style="margin-top: 15px; margin-left: 5px;"
                    >
                      <input
                        id="is_settlement_only_weekday"
                        v-model="formPayload.is_settlement_only_weekday"
                        class="form-control custom-control-input"
                        type="checkbox"
                        name="is_settlement_only_weekday"
                      >
                      <label
                        for="is_settlement_only_weekday"
                        class="custom-control-label"
                      >Working Days</label>
                    </div>
                  </div>

                  <div
                    v-else
                    class="form-group"
                  >
                    <label for="">Settlement Time:</label>
                    <the-mask
                      v-model="formPayload.settlement_time"
                      :mask="['##:##:##']"
                      placeholder="hh:mm:ss"
                      masked="true"
                      class="form-control"
                    />
                  </div>

                </div>

              </div>
            </div>

            <br>
            <h5>Transaction Fee</h5>

            <div class="row mb-1">
              <div class="col-6">
                <label for="">VAT/PPN:</label>
                <div class="input-group">
                  <input
                    v-model="formPayload.vat_amount"
                    type="text"
                    class="form-control"
                  >
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
              <div
                v-if="!isTerra"
                class="col-6"
              >
                <div class="form-group">
                  <label for="">VAT/PPN Payment On:</label>
                  <select
                    v-model="formPayload.vat_payment_on"
                    class="form-control"
                  >
                    <option
                      v-for="type in [
                        {
                          key: 'on_settlement',
                          label: 'On Settlement',
                        },
                        {
                          key: 'on_submit_transaction',
                          label: 'On Submit Transaction (Bid/Ask)',
                        }
                      ]"
                      :key="type.key"
                      :value="type.key"
                    >{{ type.label }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="">Buyer Fee Type:</label>
                  <select
                    v-model="formPayload.buyer_fee_type"
                    class="form-control"
                  >
                    <option
                      v-for="type in priceType"
                      :key="type.key"
                      :value="type.key"
                    >
                      {{ type.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <label for="">Buyer Fee Amount:</label>
                <div class="input-group">
                  <div
                    v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(formPayload.buyer_fee_type)"
                    class="input-group-prepend"
                  >
                    <span class="input-group-text">{{ currency.value }}</span>
                  </div>
                  <input
                    v-model="formPayload.buyer_fee_amount"
                    type="text"
                    class="form-control"
                  >
                  <div
                    v-if="formPayload.buyer_fee_type == 'percent'"
                    class="input-group-append"
                  >
                    <span class="input-group-text">%</span>
                  </div>
                  <div
                    v-if="['price_per_unit', 'price_per_lot'].includes(formPayload.buyer_fee_type)"
                    class="input-group-append"
                  >
                    <span
                      v-if="formPayload.buyer_fee_type == 'price_per_unit'"
                      class="input-group-text"
                    >per unit</span>
                    <span
                      v-if="formPayload.buyer_fee_type == 'price_per_lot'"
                      class="input-group-text"
                    >per lot</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!isTerra"
              class="row"
            >
              <div class="col-12">
                <div class="form-group">
                  <label for="">Buyer Fee Payment On:</label>
                  <select
                    v-model="formPayload.buyer_fee_payment_on"
                    class="form-control"
                  >
                    <option
                      v-for="(option, key) in buyerFeePaymentOn"
                      :key="key"
                      :value="key"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                  <small
                    v-if="Object.keys(buyerFeePaymentOn).includes(formPayload.buyer_fee_payment_on)"
                    class="text-info"
                  >{{ buyerFeePaymentOn[formPayload.buyer_fee_payment_on].description }}</small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="">Seller Fee Type:</label>
                  <select
                    v-model="formPayload.seller_fee_type"
                    class="form-control"
                  >
                    <option
                      v-for="type in priceType"
                      :key="type.key"
                      :value="type.key"
                    >
                      {{ type.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <label for="">Seller Fee Amount:</label>
                <div class="input-group">
                  <div
                    v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(formPayload.seller_fee_type)"
                    class="input-group-prepend"
                  >
                    <span class="input-group-text">{{ currency.value }}</span>
                  </div>
                  <input
                    v-model="formPayload.seller_fee_amount"
                    type="text"
                    class="form-control"
                  >
                  <div
                    v-if="formPayload.seller_fee_type == 'percent'"
                    class="input-group-append"
                  >
                    <span class="input-group-text">%</span>
                  </div>
                  <div
                    v-if="['price_per_unit', 'price_per_lot'].includes(formPayload.seller_fee_type)"
                    class="input-group-append"
                  >
                    <span
                      v-if="formPayload.seller_fee_type == 'price_per_unit'"
                      class="input-group-text"
                    >per unit</span>
                    <span
                      v-if="formPayload.seller_fee_type == 'price_per_lot'"
                      class="input-group-text"
                    >per lot</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!isTerra"
              class="row"
            >
              <div class="col-12">
                <div class="form-group">
                  <label for="">Seller Fee Payment On:</label>
                  <select
                    v-model="formPayload.seller_fee_payment_on"
                    class="form-control"
                  >
                    <option
                      v-for="(option, key) in sellerFeePaymentOn"
                      :key="key"
                      :value="key"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                  <small
                    v-if="Object.keys(sellerFeePaymentOn).includes(formPayload.seller_fee_payment_on)"
                    class="text-info"
                  >{{ sellerFeePaymentOn[formPayload.seller_fee_payment_on].description }}</small>
                </div>
              </div>
            </div>

            <br>
            <h5>Custom Transaction Fee</h5>

            <button
              class="btn btn-sm btn-success"
              @click="addCustomFee()"
            >
              <feather-icon
                icon="PlusIcon"
              /> Add Item
            </button><br>
            <br>
            <table class="table table-hover">
              <tr
                v-for="(customFee, index) in formPayload.custom_fees"
                :key="index"
              >
                <template v-if="customFee.user_id == null || customFee.user_id == '' || users.map(function(item){ return item.id; }).includes(customFee.user_id)">
                  <td
                    class="pl-0"
                    width="30%"
                    style="vertical-align:top"
                  >
                    <label for="">User</label>
                    <select
                      v-model="formPayload.custom_fees[index].user_id"
                      class="form-control"
                    >
                      <option value="">
                        -- Select User --
                      </option>
                      <template v-for="user in users">
                        <option
                          v-if="!formPayload.custom_fees.map(function(item){ return item.user_id; }).includes(user.id) || user.id == formPayload.custom_fees[index].user_id"
                          :key="user.id"
                          :value="user.id"
                        >
                          {{ user.code == null ? '' : (user.code + ' - ') }} {{ user.name }}
                        </option>
                      </template>
                    </select>
                  </td>
                  <td
                    class="pl-0"
                    style="vertical-align:top"
                  >

                    <div class="form-group">
                      <label for="">Buyer Fee Type:</label>
                      <select
                        v-model="customFee.buyer_fee_type"
                        class="form-control"
                      >
                        <option value="">
                          -- Select Price Type --
                        </option>
                        <option
                          v-for="type in priceType"
                          :key="type.key"
                          :value="type.key"
                        >
                          {{ type.label }}
                        </option>
                      </select>
                    </div>

                    <div
                      v-if="customFee.buyer_fee_type != '' && customFee.buyer_fee_type != null"
                      class="input-group"
                    >
                      <div
                        v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(customFee.buyer_fee_type)"
                        class="input-group-prepend"
                      >
                        <span class="input-group-text">{{ currency.value }}</span>
                      </div>
                      <input
                        v-model="customFee.buyer_fee_amount"
                        type="text"
                        class="form-control"
                        :disabled="customFee.buyer_fee_type == ''"
                      >
                      <div
                        v-if="customFee.buyer_fee_type == 'percent'"
                        class="input-group-append"
                      >
                        <span class="input-group-text">%</span>
                      </div>
                      <div
                        v-if="['price_per_unit', 'price_per_lot'].includes(customFee.buyer_fee_type)"
                        class="input-group-append"
                      >
                        <span
                          v-if="customFee.buyer_fee_type == 'price_per_unit'"
                          class="input-group-text"
                        >per unit</span>
                        <span
                          v-if="customFee.buyer_fee_type == 'price_per_lot'"
                          class="input-group-text"
                        >per lot</span>
                      </div>
                    </div>

                  </td>
                  <td
                    class="pl-0 pr-0"
                    style="vertical-align:top"
                  >

                    <div class="form-group">
                      <label for="">Seller Fee Type:</label>
                      <select
                        v-model="customFee.seller_fee_type"
                        class="form-control"
                      >
                        <option value="">
                          -- Select Price Type --
                        </option>
                        <option
                          v-for="type in priceType"
                          :key="type.key"
                          :value="type.key"
                        >
                          {{ type.label }}
                        </option>
                      </select>
                    </div>

                    <div
                      v-if="customFee.seller_fee_type != '' && customFee.seller_fee_type != null"
                      class="input-group"
                    >
                      <div
                        v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(customFee.seller_fee_type)"
                        class="input-group-prepend"
                      >
                        <span class="input-group-text">{{ currency.value }}</span>
                      </div>
                      <input
                        v-model="customFee.seller_fee_amount"
                        type="text"
                        class="form-control"
                        :disabled="customFee.seller_fee_type == ''"
                      >
                      <div
                        v-if="customFee.seller_fee_type == 'percent'"
                        class="input-group-append"
                      >
                        <span class="input-group-text">%</span>
                      </div>
                      <div
                        v-if="['price_per_unit', 'price_per_lot'].includes(customFee.seller_fee_type)"
                        class="input-group-append"
                      >
                        <span
                          v-if="customFee.seller_fee_type == 'price_per_unit'"
                          class="input-group-text"
                        >per unit</span>
                        <span
                          v-if="customFee.seller_fee_type == 'price_per_lot'"
                          class="input-group-text"
                        >per lot</span>
                      </div>
                    </div>

                  </td>
                  <td class="text-right pl-0 pr-0">
                    <button
                      class="btn btn-sm btn-danger ml-1"
                      @click="removeCustomFee(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                      />
                    </button>
                  </td>
                </template>
              </tr>
            </table>

            <br>
            <h5>Settlement Settings</h5>

            <div class="form-group">
              <label for="is_automatic_settlement">Automatic Settlement (No Need Approval):</label>
              <div
                class="custom-control custom-checkbox"
              >
                <input
                  id="is_automatic_settlement"
                  v-model="formPayload.is_automatic_settlement"
                  class="form-control custom-control-input"
                  type="checkbox"
                  name="is_automatic_settlement"
                >
                <label
                  for="is_automatic_settlement"
                  class="custom-control-label"
                >Yes</label>
              </div>
            </div>

            <template #modal-footer="{}">
              <div v-if="isLoading">
                <br>
                <b-spinner
                  class="mb-2"
                  variant="primary"
                /><br>
              </div>
              <b-button
                v-if="editUuid == null"
                variant="success"
                :disabled="isLoading"
                @click="createAuction()"
              >
                Save Auction
              </b-button>
              <b-button
                v-else
                variant="success"
                :disabled="isLoading"
                @click="updateAuction()"
              >
                Save Auction
              </b-button>
            </template>
          </b-modal>
        </div>

        <div class="col-12">
          <div class="form-row mb-1">
            <div class="col-lg-4">
              <label>Filter by Asset</label>
              <select
                v-model="filter.commodity_slug"
                class="form-control form-control-sm"
              >
                <option
                  selected
                  value=""
                >
                  -- Select Asset --
                </option>
                <option
                  v-for="commodity in commodities"
                  :key="commodity.slug"
                  :value="commodity.slug"
                >
                  {{ commodity.name }}
                </option>
              </select>
            </div>
            <div class="col-lg-4">
              <label>Filter by Depository</label>
              <select
                v-model="filter.warehouse_slug"
                class="form-control form-control-sm"
              >
                <option
                  selected
                  value=""
                >
                  -- Select Depository --
                </option>
                <option
                  v-for="warehouse in warehouses"
                  :key="warehouse.slug"
                  :value="warehouse.slug"
                >
                  {{ warehouse.name }}
                </option>
              </select>
            </div>
            <div class="col-lg-4">
              <label>Filter by Daterange</label>
              <date-picker
                v-model="filter.daterange_filter"
                placeholder="Filter by date range"
                :value="filter.daterange_filter"
                range
                format="YYYY/MM/DD"
                range-separator="-"
                input-class="form-control form-control-sm"
              />
            </div>
          </div>
          <!-- <div class="form-row">
                    <div class="col-lg-4"></div>
                    <div class="col-lg-4"></div>
                    <div class="col-lg-4">
                        <input type="text" v-model="filter.search" placeholder="Search by commodity.." class="form-control form-control-sm float-right" style="width: 200px;">
                    </div>
                </div> -->
        </div>
      </div>
    </div>
    <div class="table-responsive-sm">
      <table class="table b-table">
        <thead>
          <tr>
            <th width="25%">
              Description
            </th>
            <th>Price</th>
            <th>Time</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td
              colspan="4"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in result.data"
            v-else
            :key="item.uuid"
          >
            <td>
              {{ item.commodity.name }}<br>
              <strong>{{ item.warehouse.name }}</strong>
            </td>
            <td>
              <div v-if="item.trading_algorithm != 'seller_auction'">
                Open: <strong :class="item.price_change_percent > 0 ? 'text-success' : (item.price_change_percent == 0 ? 'text-default' : 'text-danger')">{{ item.sob_formatted }}</strong><br>
                Last: <strong :class="item.price_change_percent > 0 ? 'text-success' : (item.price_change_percent == 0 ? 'text-default' : 'text-danger')">{{ item.last_price_formatted }}</strong><br>
                Change: <strong :class="item.price_change_percent > 0 ? 'text-success' : (item.price_change_percent == 0 ? 'text-default' : 'text-danger')">{{ item.price_change_percent.toFixed(2) }}%</strong><br>
              </div>
              <div v-else>
                Seller: <strong>{{ (item.seller == null ? '' : ((item.seller.code == null ? '' : (item.seller.code + ' - ')) + item.seller.name)) }}</strong><br>
                Starting Price: <strong>{{ item.bid_starting_price_formatted }}</strong><br>
                Bid Minimum Price: <strong>{{ item.bid_minimum_price_formatted }}</strong><br>
                Buy Now Price: <strong>{{ item.buy_now_price_formatted }}</strong><br>
              </div>
            </td>
            <td v-if="item.times.length > 0">
              <div v-if="item.trading_algorithm != 'seller_auction'">
                Date: {{ item.date }}<br>
                Start: <strong>{{ item.times[0].start_at_time_only }}</strong><br>
                End: <strong>{{ item.times[item.times.length - 1].end_at_time_only }}</strong><br>
              </div>
              <div v-else>
                Start: <strong>{{ item.times[0].start_at }}</strong><br>
                End: <strong>{{ item.times[item.times.length - 1].end_at }}</strong><br>
              </div>
              Repeat: <strong class="text-capitalize">{{ item.repeat_type == null ? '-' : item.repeat_type }}</strong>
            </td>
            <td style="text-transform: capitalize;">
              {{ item.status }}
            </td>
            <td>
              <div
                class="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-success btn-sm"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Detail Auction"
                  @click="goToDetail(item.uuid)"
                >
                  <eye-icon
                    size="1x"
                    class="custom-class"
                  />
                </button>
                <button
                  v-if="checkPermission('update auctions')"
                  class="btn btn-info btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit Auction"
                  @click="editAuction(item)"
                >
                  <edit-icon
                    size="1x"
                    class="custom-class"
                  />
                </button>
                <button
                  v-if="checkPermission('delete auctions')"
                  class="btn btn-danger btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Delete Auction"
                  @click="deleteAuction(item.uuid)"
                >
                  <trash-2-icon
                    size="1x"
                    class="custom-class"
                  />
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="4"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="result.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BFormTimepicker, BSpinner,
} from 'bootstrap-vue'
import {
  EyeIcon, Trash2Icon, PlusCircleIcon, EditIcon,
} from 'vue-feather-icons'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { TheMask } from 'vue-the-mask'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'

export default {
  title() {
    return `List Auction`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    DatePicker,
    EyeIcon,
    EditIcon,
    flatPickr,
    Trash2Icon,
    PlusCircleIcon,
    BSpinner,
    BFormTimepicker,
    TheMask,
    vSelect,
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      filter: {
        commodity_slug: '',
        warehouse_slug: '',
        daterange_filter: [],
        search: null,
      },
      currency: {
        value: 'IDR',
      },
      editUuid: null,
      formPayload: {
        commodity_slug: '',
        warehouse_slug: '',
        sob: '',
        settlement_days: 2,
        settlement_time: '',
        is_settlement_only_weekday: true,
        is_automatic_settlement: true,
        trading_algorithm: 'running_trade',
        restricted_to_trade: false,
        price_movement: 50,
        margin_minimum_for_buy_type: 'percent',
        margin_minimum_for_buy: 100,
        buyer_penalty_type: 'fixed',
        buyer_penalty_amount: 0,
        seller_compensation_type: 'fixed',
        seller_compensation_amount: 0,
        margin_for_sell_type: 'percent',
        margin_for_sell_amount: 0,
        seller_penalty_type: 'percent',
        seller_penalty_amount: 0,
        buyer_compensation_type: 'percent',
        buyer_compensation_amount: 0,
        limit_price_increase_type: 'percent',
        limit_price_decrease_type: 'percent',
        limit_price_increase: 2,
        limit_price_decrese: 2,
        minimum_lot_transaction: 1,
        lot_tolerance: 0,
        buyer_fee_type: 'fixed',
        buyer_fee_amount: 0,
        buyer_fee_payment_on: 'on_bid',
        seller_fee_type: 'fixed',
        seller_fee_amount: 0,
        seller_fee_payment_on: 'on_settlement',
        vat_amount: 0,
        repeat_type: '',
        repeat_at: '',
        repeat_skip_weekend_holiday: 0,
        repeat_warehouses: [],
        date: '',
        times: [this.getTimePayload()],
        custom_fees: [],
        seller_id: '',
        lot_to_trade: '',
        bid_starting_price: '',
        bid_minimum_price: '',
        buy_now_price: '',
      },
      result: {},
      items: [],
      warehouses: [],
      commodities: [],
      isLoading: false,
      isTerra: process.env.IS_TERRA == 'true',
      priceType: [
        {
          key: 'percent',
          label: 'Percent',
        },
        {
          key: 'fixed',
          label: 'Fixed Price',
        },
        {
          key: 'price_per_unit',
          label: 'Price per Unit',
        },
        {
          key: 'price_per_lot',
          label: 'Price per Lot',
        },
      ],
      sellerFeePaymentOn: Object,
      buyerFeePaymentOn: Object,
      users: [],
      maskedUsers: [],
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  mounted() {
    const context = this
    this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', payload => {
      if (payload.type == 'auction') {
        context.getData(context.currentPage || 1)
      }
    })
  },
  created() {
    this.loadFilterData()

    // Set default date filter
    const startDate = moment().format('YYYY/MM/DD')
    const endDate = moment().add(1, 'years').format('YYYY/MM/DD')
    this.filter.daterange_filter = [new Date(String(startDate)), new Date(String(endDate))]

    this.getData()
    this.getFormData()
    // this.getCommodityBySlug();
  },
  methods: {
    getCustomFeePayload() {
      return {
        user_id: '',
        buyer_fee_type: '',
        buyer_fee_amount: 0,
        seller_fee_type: '',
        seller_fee_amount: 0,
      }
    },
    addCustomFee() {
      this.formPayload.custom_fees.push(
        this.getCustomFeePayload(),
      )
    },
    removeCustomFee(index) {
      this.formPayload.custom_fees.splice(index, 1)
    },
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = page
      queryParams.start_at_daterange = moment(queryParams.daterange_filter[0]).format('DD/MM/YYYY')
      queryParams.start_at_daterange += `-${moment(queryParams.daterange_filter[1]).format('DD/MM/YYYY')}`

      this.$http.get('/admin/auction', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    getFormData() {
      this.$http.get('/admin/miscellaneous/auction-seller-fee-payment-list')
        .then(response => {
          this.sellerFeePaymentOn = response.data.data
        })

      this.$http.get('/admin/miscellaneous/auction-buyer-fee-payment-list')
        .then(response => {
          this.buyerFeePaymentOn = response.data.data
        })

      this.$http.get('/admin/users?user_type=normal&per_page=10000&sort_by_code=desc')
        .then(response => {
          this.users = response.data.data.data
          const context = this
          context.users.forEach(user => {
            context.maskedUsers.push({
              userId: user.id,
              userLabel: (user.code == null ? '' : (`${user.code} - `)) + user.name,
            })
          })
        })
    },
    loadFilterData() {
      this.$http.get('/public/commodities/warehouses')
        .then(response => {
          this.warehouses = response.data.data
        })
      this.$http.get('/public/commodities/')
        .then(response => {
          this.commodities = response.data.data
        })
    },
    getCommodityBySlug(slug) {
      this.$http.get(`/public/commodities/currency/${slug}`, { useCache: true })
        .then(response => {
          this.currency.value = response.data.data.code
        })
    },
    goToDetail(uuid) {
      this.$router.push({ name: 'auctions.show', params: { uuid } })
    },
    editAuction(item) {
      const times = []
      item.times.forEach(time => {
        times.push({
          start_at: time.start_at_time_only,
          break_at: time.break_at_time_only,
          break_finish_at: time.break_finish_at_time_only,
          end_at: time.end_at_time_only,
          id: time.id,
        })
      })
      this.currency.value = item.commodity.currency_code
      this.editUuid = item.uuid

      this.formPayload = {
        commodity_slug: item.commodity.slug,
        warehouse_slug: item.warehouse.slug,
        current_sob: item.sob,
        settlement_days: item.settlement_days,
        settlement_time: item.settlement_time,
        is_settlement_only_weekday: item.is_settlement_only_weekday,
        is_automatic_settlement: item.is_automatic_settlement,
        trading_algorithm: item.trading_algorithm,
        restricted_to_trade: item.restricted_to_trade,
        price_movement: item.price_movement,
        margin_minimum_for_buy_type: item.margin_minimum_for_buy_type,
        margin_minimum_for_buy: item.margin_minimum_for_buy,
        buyer_penalty_type: item.buyer_penalty_type,
        buyer_penalty_amount: item.buyer_penalty_amount,
        seller_compensation_type: item.seller_compensation_type,
        seller_compensation_amount: item.seller_compensation_amount,
        margin_for_sell_type: item.margin_for_sell_type,
        margin_for_sell_amount: item.margin_for_sell_amount,
        seller_penalty_type: item.seller_penalty_type,
        seller_penalty_amount: item.seller_penalty_amount,
        buyer_compensation_type: item.buyer_compensation_type,
        buyer_compensation_amount: item.buyer_compensation_amount,
        limit_price_increase_type: item.limit_price_increase_type,
        limit_price_decrease_type: item.limit_price_decrease_type,
        limit_price_increase: item.limit_price_increase,
        limit_price_decrese: item.limit_price_decrese,
        minimum_lot_transaction: item.minimum_lot_transaction,
        lot_tolerance: item.lot_tolerance,
        buyer_fee_type: item.buyer_fee_type,
        buyer_fee_amount: item.buyer_fee_amount,
        buyer_fee_payment_on: item.buyer_fee_payment_on,
        seller_fee_type: item.seller_fee_type,
        seller_fee_amount: item.seller_fee_amount,
        seller_fee_payment_on: item.seller_fee_payment_on,
        vat_amount: item.vat_amount,
        repeat_type: item.repeat_type,
        repeat_at: item.repeat_at,
        repeat_skip_weekend_holiday: item.repeat_skip_weekend_holiday,
        repeat_warehouses: item.repeat_warehouses,
        date: item.date,
        times,
        custom_fees: item.custom_fees,
        seller_id: item.seller == null ? '' : item.seller.id,
        lot_to_trade: item.lot_to_trade,
        bid_starting_price: item.bid_starting_price,
        bid_minimum_price: item.bid_minimum_price,
        buy_now_price: item.buy_now_price,
      }
      this.$bvModal.show('form-auction-modal')
    },
    deleteAuction(uuid) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this auction?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/admin/auction/${uuid}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Auction successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    getTimePayload() {
      return {
        start_at: '09:00:00',
        break_at: '',
        break_finish_at: '',
        end_at: '15:00:00',
      }
    },
    addTime() {
      this.formPayload.times.push(
        this.getTimePayload(),
      )
    },
    removeTime(index) {
      this.formPayload.times.splice(index, 1)
    },
    createAuction() {
      this.isLoading = true
      this.prepareData()

      this.$http.post('/admin/auction', this.formPayload)
        .then(response => {
          this.$bvModal.hide('form-auction-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Auction successfully created')

          this.cleanupForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    updateAuction() {
      this.isLoading = true
      this.prepareData()

      this.$http.patch(`/admin/auction/${this.editUuid}`, this.formPayload)
        .then(response => {
          this.$bvModal.hide('form-auction-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Auction successfully updated!')

          this.cleanupForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    prepareData() {
      this.formPayload.repeat_skip_weekend_holiday = this.formPayload.repeat_skip_weekend_holiday ? 1 : 0
      this.formPayload.is_settlement_only_weekday = this.formPayload.is_settlement_only_weekday ? 1 : 0
      this.formPayload.restricted_to_trade = this.formPayload.restricted_to_trade ? 1 : 0
      this.formPayload.is_automatic_settlement = this.formPayload.is_automatic_settlement ? 1 : 0

      // Force payload for Terra
      if (this.isTerra) {
        this.formPayload.settlement_days = 0
        this.formPayload.is_settlement_only_weekday = 0
        this.formPayload.margin_minimum_for_buy_type = 'percent'
        this.formPayload.margin_minimum_for_buy = 100
        this.formPayload.buyer_penalty_type = 'percent'
        this.formPayload.buyer_penalty_amount = 0
        this.formPayload.seller_compensation_type = 'percent'
        this.formPayload.seller_compensation_amount = 0
        this.formPayload.margin_for_sell_type = 'percent'
        this.formPayload.margin_for_sell_amount = 0
        this.formPayload.seller_penalty_type = 'percent'
        this.formPayload.seller_penalty_amount = 0
        this.formPayload.buyer_compensation_type = 'percent'
        this.formPayload.buyer_compensation_amount = 0
        this.formPayload.buyer_fee_payment_on = 'on_bid'
        this.formPayload.seller_fee_payment_on = 'on_settlement'
        this.formPayload.vat_payment_on = 'on_settlement'
      }

      // Covert comma (,) to point (.)
      const convertDecimals = [
        'sob',
        'price_movement',
        'margin_minimum_for_buy',
        'buyer_penalty_amount',
        'seller_compensation_amount',
        'margin_for_sell_amount',
        'seller_penalty_amount',
        'buyer_compensation_amount',
        'limit_price_increase',
        'limit_price_decrese',
        'lot_tolerance',
        'vat_amount',
        'bid_starting_price',
        'bid_minimum_price',
        'buy_now_price',
      ]
      convertDecimals.forEach(key => {
        this.formPayload[key] = String(this.formPayload[key]).replace(/,/g, '.')
        if (String(this.formPayload[key]) == 'undefined' || String(this.formPayload[key]) == 'null') {
          this.formPayload[key] = ''
        }
      })

      if (this.formPayload.settlement_days > 0) {
        this.formPayload.settlement_time = ''
      }
    },
    range(start, end) {
      return Array.from({ length: end - start + 1 }, (_, i) => i)
    },
    cleanupForm() {
      this.editUuid = null
      this.formPayload = {
        commodity_slug: '',
        warehouse_slug: '',
        sob: null,
        settlement_days: 2,
        settlement_time: '',
        is_settlement_only_weekday: true,
        is_automatic_settlement: true,
        trading_algorithm: 'running_trade',
        restricted_to_trade: false,
        price_movement: 50,
        // Margin for Buy
        margin_minimum_for_buy_type: 'percent',
        margin_minimum_for_buy: 100,
        buyer_penalty_type: 'fixed',
        buyer_penalty_amount: 0,
        seller_compensation_type: 'fixed',
        seller_compensation_amount: 0,
        // Margin for Sell
        margin_for_sell_type: 'percent',
        margin_for_sell_amount: 0,
        seller_penalty_type: 'percent',
        seller_penalty_amount: 0,
        buyer_compensation_type: 'percent',
        buyer_compensation_amount: 0,
        limit_price_increase_type: 'percent',
        limit_price_decrease_type: 'percent',
        limit_price_increase: 2,
        limit_price_decrese: 2,
        minimum_lot_transaction: 1,
        lot_tolerance: 0,
        buyer_fee_type: 'fixed',
        buyer_fee_amount: 0,
        buyer_fee_payment_on: 'on_bid',
        seller_fee_type: 'fixed',
        seller_fee_amount: 0,
        seller_fee_payment_on: 'on_settlement',
        vat_amount: 0,
        repeat_type: '',
        repeat_at: '',
        repeat_skip_weekend_holiday: 0,
        repeat_warehouses: [],
        date: '',
        times: [this.getTimePayload()],
        custom_fees: [],
        lot_to_trade: '',
        bid_starting_price: '',
        bid_minimum_price: '',
        buy_now_price: '',
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "vue-select/src/scss/vue-select.scss";
</style>
